import React from "react";

// Admin Imports
import MainDashboard from "views/admin/default";
import Profile from "views/admin/profile";
import DataTables from "views/admin/tables";

import MaintenanceMode from "views/admin/maintenance";
import Users from "views/admin/users";
import AddUsers from "views/admin/users/addUsers";
import CMS from "views/admin/CMS";
import AddCMS from "views/admin/CMS/addCMS";
import CMSDetails from "views/admin/CMS/CMSDetails";
import ComingSoon from "components/comingSoon";
import AddRoles from "views/admin/roles/addRoles";
import Categories from "views/admin/categories";
import AddCategories from "views/admin/categories/addCategory";
import Modifiers from "views/admin/modifiers";
import AddModifiers from "views/admin/modifiers/addModifier";
import Restaurant from "views/admin/restaurant";
import AddRestaurant from "views/admin/restaurant/addRestaurant";
import ViewRestaurant from "views/admin/restaurant/viewRestaurant";
import Cuisines from "views/admin/cuisines";
import AddCuisines from "views/admin/cuisines/addCuisine";

// Auth Imports
import SignIn from "views/auth/SignIn";
import TwoStepAuth from "views/auth/TwoStepAuth";
import ResetPassword from "views/auth/ResetPassword";
// import SignUp from "views/auth/SignUp";
// import ForgotPassword from "views/auth/ForgotPassword";
// import ConformPassword from "views/auth/ConfirmPassword";
import Customers from "views/admin/customers";
import CustomerDetails from "views/admin/customers/customerDetails";
import Orders from "views/admin/orders";
import OrderDetails from "views/admin/orders/components/orderDetails";

const routes = (userType: string) => [
  {
    name: "Sign In",
    layout: "/auth",
    path: `${userType}/auth/sign-in`,
    component: <SignIn />,
  },
  {
    name: "Reset Password",
    layout: "/auth",
    path: `${userType}/auth/reset-password`,
    component: <ResetPassword />,
  },
  {
    name: "Two Step Auth",
    layout: "/auth",
    path: `${userType}/auth/2fa`,
    component: <TwoStepAuth />,
  },
  {
    name: "Dashboard",
    layout: `/${userType}`,
    path: "default",
    component: <MainDashboard />,
  },
  {
    name: "Data Tables",
    layout: `/${userType}`,
    path: "data-tables",
    component: <DataTables />,
  },
  {
    name: "Profile",
    layout: `/${userType}`,
    path: "profile",
    component: <Profile />,
  },
  {
    name: "All CMS Pages",
    layout: `/${userType}`,
    path: "all-cms",
    component: <CMS />,
  },
  {
    name: "Add CMS Page",
    layout: `/${userType}`,
    path: "add-cms",
    component: <AddCMS />,
  },
  {
    name: "Edit CMS Page",
    layout: `/${userType}`,
    path: "edit-cms/:id",
    component: <AddCMS />,
  },
  {
    name: "CMS Details",
    layout: `/${userType}`,
    path: "cms-details/:id",
    component: <CMSDetails />,
  },

  {
    name: "Maintenance Mode",
    layout: `/${userType}`,
    path: "maintenance-mode",
    component: <MaintenanceMode />,
  },

  {
    name: "All Users",
    layout: `/${userType}`,
    path: "all-users",
    component: <Users />,
  },
  {
    name: "Add Users",
    layout: `/${userType}`,
    path: "add-users",
    component: <AddUsers />,
  },
  {
    name: "Edit Users",
    layout: `/${userType}`,
    path: "edit-users/:id",
    component: <AddUsers />,
  },
  {
    name: "All Categories",
    layout: `/${userType}`,
    path: "all-categories",
    component: <Categories />,
  },
  {
    name: "Add Categories",
    layout: `/${userType}`,
    path: "add-categories",
    component: <AddCategories />,
  },
  {
    name: "Edit Categories",
    layout: `/${userType}`,
    path: "edit-categories/:id",
    component: <AddCategories />,
  },
  {
    name: "All Modifiers",
    layout: `/${userType}`,
    path: "all-modifiers",
    component: <Modifiers />,
  },
  {
    name: "Add Modifier",
    layout: `/${userType}`,
    path: "add-modifier",
    component: <AddModifiers branchId={""} modifiersData={[]} />,
  },
  {
    name: "Edit Modifier",
    layout: `/${userType}`,
    path: "edit-modifier/:id",
    component: <AddModifiers branchId={""} modifiersData={[]} />,
  },
  {
    name: "All Customers",
    layout: `/${userType}`,
    path: "all-customers",
    component: <Customers />,
  },
  {
    name: "Customer Details",
    layout: `/${userType}`,
    path: "customer-details/:id",
    component: <CustomerDetails />,
  },
  {
    name: "Add Roles",
    layout: `/${userType}`,
    path: "add-roles",
    component: <AddRoles />,
  },
  {
    name: "Coming Soon",
    layout: `/${userType}`,
    path: "coming-soon",
    component: <ComingSoon />,
  },
  {
    name: "All Restaurant",
    layout: `/${userType}`,
    path: "all-restaurant",
    component: <Restaurant />,
  },
  {
    name: "Add Restaurant",
    layout: `/${userType}`,
    path: "add-restaurant",
    component: <AddRestaurant />,
  },
  {
    name: "Edit Restaurant",
    layout: `/${userType}`,
    path: "edit-restaurant/:id",
    component: <AddRestaurant />,
  },
  {
    name: "View Restaurant",
    layout: `/${userType}`,
    path: "view-restaurant/:id",
    component: <ViewRestaurant />,
  },
  {
    name: "All Cuisines",
    layout: `/${userType}`,
    path: "all-cuisines",
    component: <Cuisines />,
  },
  {
    name: "Add Cuisine",
    layout: `/${userType}`,
    path: "add-cuisine",
    component: <AddCuisines />,
  },
  {
    name: "Edit Cuisine",
    layout: `/${userType}`,
    path: "edit-cuisine/:id",
    component: <AddCuisines />,
  },

  {
    name: "All Orders",
    layout: `/${userType}`,
    path: "all-orders",
    component: <Orders />,
  },
  {
    name: "Order Details",
    layout: `/${userType}`,
    path: "order-details/:orderId",
    component: <OrderDetails />,
  },
];
export default routes;
