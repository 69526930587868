import React from "react";
import { ErrorMessage, Form, Field, Formik } from "formik";
import Card from "components/card";
import { MdClose, MdFileUpload } from "react-icons/md";
import { CircularProgress } from "@mui/material";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import envConfig from "../../../../env/env.json";
import Select from "react-select";
import { IKUpload, IKContext } from "imagekitio-react";
import {
  publicKey,
  urlEndpoint,
  authenticator,
} from "controller/common/imageKit/imageKit";
import { RestaurantFormProps, Address } from "../restaurant.interface";
import { MdSearch, MdLocationOn } from "react-icons/md";
import WorkingHoursSection from "../components/workinghours";

const mapContainerStyle = {
  height: "400px",
  width: "100%",
  borderRadius: "0.75rem",
};

// First, let's create a reusable FormSection component
const FormSection: React.FC<{
  title: string;
  children: React.ReactNode;
  className?: string;
}> = ({ title, children, className = "" }) => (
  <div
    className={`mb-8 rounded-2xl bg-white p-6 shadow-sm dark:bg-navy-800 ${className}`}
  >
    <h3 className="mb-6 text-lg font-semibold text-navy-700 dark:text-white">
      {title}
    </h3>
    <div className="space-y-4">{children}</div>
  </div>
);

const RestaurantForm: React.FC<RestaurantFormProps> = ({
  initialValues,
  validationSchema,
  onSubmit,
  loading,
  options = [],
  location,
  address,
  // handleNext,
  categoryOptions = [],
  cuisineOptions = [],
  pinCode,
  mapCenterLocation,
  isMapVisible,
  handleMapClick,
  handlePinCodeChange,
  handlePinCodeBlur,
  handlePinCodeKeyDown,
  daysOfWeek,
  paymentStatusOptions = [],
  isBannerUploading,
  bannerInputRef,
  onBannerSuccess,
  onBannerError,
  onBannerUploadProgress,
  handleDeleteBannerFile,
  bannerImage,
  isEditMode,
  handleBannerUpload,
  handleLogoUpload,

  isUploading,
  logoInputRef,
  onLogoSuccess,
  onLogoError,
  onUploadProgress,
  handleDeleteLogoFile,
  brandImage,
}) => {

  return (
    <div className="min-h-screen bg-gray-50 p-4 dark:bg-navy-900 lg:p-8">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize={true}
      >
        {({ setFieldValue, values, errors, touched }) => (
          <Form className="mx-auto max-w-5xl space-y-6">
            {/* Basic Information */}
            <FormSection title="Basic Information">
              <div className="grid gap-6 md:grid-cols-2">
                {/* Restaurant Name */}
                <div className="relative">
                  <label className="mb-2 block text-sm font-medium text-navy-700 dark:text-white">
                    Restaurant Name*
                  </label>
                  <Field
                    type="text"
                    name="name"
                    className="w-full rounded-lg border border-gray-200 bg-white px-4 py-2.5 text-sm transition-colors focus:border-brand-500 focus:outline-none dark:border-navy-600 dark:bg-navy-700"
                  />
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="mt-1 text-sm text-red-500"
                  />
                </div>

                {/* Contact Details */}
                <div className="relative">
                  <label className="mb-2 block text-sm font-medium text-navy-700 dark:text-white">
                    Contact Details*
                  </label>
                  <Field
                    type="text"
                    name="contactDetails"
                    className="w-full rounded-lg border border-gray-200 bg-white px-4 py-2.5 text-sm transition-colors focus:border-brand-500 focus:outline-none dark:border-navy-600 dark:bg-navy-700"
                  />
                  <ErrorMessage
                    name="contactDetails"
                    component="div"
                    className="mt-1 text-sm text-red-500"
                  />
                </div>

                {/* Username/Email */}
                <div className="relative">
                  <label className="mb-2 block text-sm font-medium text-navy-700 dark:text-white">
                    Email* {isEditMode && "(Read-Only)"}
                  </label>
                  <Field
                    type="email"
                    name="username"
                    className={`w-full rounded-lg border border-gray-200 bg-white px-4 py-2.5 text-sm transition-colors focus:border-brand-500 focus:outline-none dark:border-navy-600 dark:bg-navy-700 ${
                      isEditMode ? "bg-gray-50 text-gray-500" : ""
                    }`}
                    readOnly={isEditMode}
                  />
                  <ErrorMessage
                    name="username"
                    component="div"
                    className="mt-1 text-sm text-red-500"
                  />
                </div>

                {/* Password */}
                <div className="relative">
                  <label className="mb-2 block text-sm font-medium text-navy-700 dark:text-white">
                    Password* {isEditMode && "(Read-Only)"}
                  </label>
                  <Field
                    type="password"
                    name="password"
                    className={`w-full rounded-lg border border-gray-200 bg-white px-4 py-2.5 text-sm transition-colors focus:border-brand-500 focus:outline-none dark:border-navy-600 dark:bg-navy-700 ${
                      isEditMode ? "bg-gray-50 text-gray-500" : ""
                    }`}
                    readOnly={isEditMode}
                  />
                  <ErrorMessage
                    name="password"
                    component="div"
                    className="mt-1 text-sm text-red-500"
                  />
                </div>
              </div>

              {/* Description */}
              <div className="relative mt-4">
                <label className="mb-2 block text-sm font-medium text-navy-700 dark:text-white">
                  Description
                </label>
                <Field
                  as="textarea"
                  name="description"
                  rows="4"
                  className="w-full rounded-lg border border-gray-200 bg-white px-4 py-2.5 text-sm transition-colors focus:border-brand-500 focus:outline-none dark:border-navy-600 dark:bg-navy-700"
                />
                <ErrorMessage
                  name="description"
                  component="div"
                  className="mt-1 text-sm text-red-500"
                />
              </div>
            </FormSection>

            {/* Business Details */}
            <FormSection title="Business Details">
              <div className="grid gap-6 md:grid-cols-2">
                {/* Payment Status */}
                <div className="relative">
                  <label className="mb-2 block text-sm font-medium text-navy-700 dark:text-white">
                    Payment Status*
                  </label>
                  <Select
                    options={paymentStatusOptions}
                    name="paymentStatus"
                    onChange={(selectedOption) =>
                      setFieldValue("paymentStatus", selectedOption?.value)
                    }
                    value={paymentStatusOptions.find(
                      (option) => option.value === values.paymentStatus
                    )}
                    className="react-select-container"
                    classNamePrefix="react-select"
                  />
                  <ErrorMessage
                    name="paymentStatus"
                    component="div"
                    className="mt-1 text-sm text-red-500"
                  />
                </div>

                {/* Type */}
                <div className="relative">
                  <label className="mb-2 block text-sm font-medium text-navy-700 dark:text-white">
                    Type*
                  </label>
                  <Select
                    options={options}
                    name="type"
                    onChange={(selectedOption) =>
                      setFieldValue("type", selectedOption?.value)
                    }
                    value={options.find(
                      (option) => option.value === values.type
                    )}
                    className="react-select-container"
                    classNamePrefix="react-select"
                  />
                  <ErrorMessage
                    name="type"
                    component="div"
                    className="mt-1 text-sm text-red-500"
                  />
                </div>

                {/* Order Type Multi-Select */}
                <div className="relative">
                  <label className="mb-2 block text-sm font-medium text-navy-700 dark:text-white">
                    Order Type*
                  </label>
                  <Select
                    isMulti
                    options={[
                      { value: "Dine In", label: "Dine In" },
                      { value: "Delivery", label: "Delivery" },
                      { value: "Pickup", label: "Pickup" },
                    ]}
                    name="orderType"
                    value={values?.orderType?.map((typeId: any) => ({
                      value: typeId,
                      label: typeId,
                    }))}
                    onChange={(selectedOptions) => {
                      const formattedOrderTypes = selectedOptions.map(
                        (option) => option.value
                      );
                      setFieldValue("orderType", formattedOrderTypes);
                    }}
                    className={`${
                      errors.orderType && touched.orderType
                        ? "border-red-500"
                        : ""
                    }`}
                  />
                  <ErrorMessage
                    name="orderType"
                    component="div"
                    className="mt-1 text-sm text-red-500"
                  />
                </div>

                {/* Platform Fee */}
                <div className="relative">
                  <label className="mb-2 block text-sm font-medium text-navy-700 dark:text-white">
                    Platform Fee*
                  </label>
                  <Field
                    type="number"
                    name="platformFee"
                    min="0"
                    step="0.01"
                    className="w-full rounded-lg border border-gray-200 bg-white px-4 py-2.5 text-sm transition-colors focus:border-brand-500 focus:outline-none dark:border-navy-600 dark:bg-navy-700"
                  />
                  <ErrorMessage
                    name="platformFee"
                    component="div"
                    className="mt-1 text-sm text-red-500"
                  />
                </div>

                {/* Tax */}
                <div className="relative">
                  <label className="mb-2 block text-sm font-medium text-navy-700 dark:text-white">
                    Tax (%)*
                  </label>
                  <Field
                    type="number"
                    name="tax"
                    min="0"
                    max="100"
                    step="0.01"
                    className="w-full rounded-lg border border-gray-200 bg-white px-4 py-2.5 text-sm transition-colors focus:border-brand-500 focus:outline-none dark:border-navy-600 dark:bg-navy-700"
                  />
                  <ErrorMessage
                    name="tax"
                    component="div"
                    className="mt-1 text-sm text-red-500"
                  />
                </div>
                {/* Cancellation Fee */}
                <div className="relative">
                  <label className="mb-2 block text-sm font-medium text-navy-700 dark:text-white">
                    Cancellation Fee (%)*
                  </label>
                  <Field
                    type="number"
                    name="cancellationFee"
                    min="0"
                    max="100"
                    step="0.01"
                    className="w-full rounded-lg border border-gray-200 bg-white px-4 py-2.5 text-sm transition-colors focus:border-brand-500 focus:outline-none dark:border-navy-600 dark:bg-navy-700"
                  />
                  <ErrorMessage
                    name="cancellationFee"
                    component="div"
                    className="mt-1 text-sm text-red-500"
                  />
                </div>

                {/* Cancellation Hours */}
                <div className="relative">
                  <label className="mb-2 block text-sm font-medium text-navy-700 dark:text-white">
                    Cancellation Time (hours)*
                  </label>
                  <Field
                    type="number"
                    name="cancellationHours"
                    min="0"
                    step="1"
                    className="w-full rounded-lg border border-gray-200 bg-white px-4 py-2.5 text-sm transition-colors focus:border-brand-500 focus:outline-none dark:border-navy-600 dark:bg-navy-700"
                  />
                  <ErrorMessage
                    name="cancellationHours"
                    component="div"
                    className="mt-1 text-sm text-red-500"
                  />
                </div>
                {/* Delivery Charge */}
                <div className="relative">
                  <label className="mb-2 block text-sm font-medium text-navy-700 dark:text-white">
                    Delivery Charge*
                  </label>
                  <Field
                    type="number"
                    name="deliveryCharge"
                    min="0"
                    step="1"
                    className="w-full rounded-lg border border-gray-200 bg-white px-4 py-2.5 text-sm transition-colors focus:border-brand-500 focus:outline-none dark:border-navy-600 dark:bg-navy-700"
                  />
                  <ErrorMessage
                    name="deliveryCharge"
                    component="div"
                    className="mt-1 text-sm text-red-500"
                  />
                </div>
              </div>
            </FormSection>

            {/* Categories & Cuisine */}
            <FormSection title="Categories & Cuisine">
              <div className="grid gap-6 md:grid-cols-2">
                {/* Category */}
                <div className="relative">
                  <label className="mb-2 block text-sm font-medium text-navy-700 dark:text-white">
                    Category*
                  </label>
                  <Select
                    isMulti
                    options={categoryOptions}
                    name="category"
                    value={values.category.map(
                      (catId: any) =>
                        categoryOptions.find(
                          (cat: any) => cat.value === catId
                        ) || {
                          value: "undefined",
                          label: "Unknown Category",
                        }
                    )}
                    onChange={(selectedOptions) => {
                      const formattedCategories = selectedOptions.map(
                        (option) => option.value
                      );
                      setFieldValue("category", formattedCategories);
                    }}
                    className={`${
                      errors.category && touched.category
                        ? "border-red-500"
                        : ""
                    }`}
                  />
                  <ErrorMessage
                    name="category"
                    component="div"
                    className="mt-1 text-sm text-red-500"
                  />
                </div>

                {/* Cuisine */}
                <div className="relative">
                  <label className="mb-2 block text-sm font-medium text-navy-700 dark:text-white">
                    Cuisine*
                  </label>
                  <Select
                    isMulti
                    options={cuisineOptions}
                    name="cuisine"
                    value={values.cuisine.map(
                      (cuiId: any) =>
                        cuisineOptions.find(
                          (cui: any) => cui.value === cuiId
                        ) || {
                          value: "undefined",
                          label: "Unknown Cuisine",
                        }
                    )}
                    onChange={(selectedOptions) => {
                      const formattedCuisines = selectedOptions.map(
                        (option) => option.value
                      );
                      setFieldValue("cuisine", formattedCuisines);
                    }}
                    className={`${
                      errors.cuisine && touched.cuisine ? "border-red-500" : ""
                    }`}
                  />
                  <ErrorMessage
                    name="cuisine"
                    component="div"
                    className="mt-1 text-sm text-red-500"
                  />
                </div>
              </div>
            </FormSection>

            {/* Location */}
            <FormSection title="Location">
              {/* Pin Code Search */}
              <div className="mb-6">
                <div className="relative">
                  <div className="flex items-center gap-2 rounded-lg border border-gray-200 p-2 transition-all duration-200 focus-within:border-brand-500 focus-within:shadow-lg dark:border-navy-600">
                    <MdSearch className="text-gray-400" size={20} />
                    <input
                      type="text"
                      value={pinCode}
                      onChange={handlePinCodeChange}
                      onBlur={handlePinCodeBlur}
                      onKeyDown={handlePinCodeKeyDown}
                      className="bg-transparent w-full text-sm text-navy-700 outline-none placeholder:text-gray-400 dark:text-white"
                      placeholder="Enter pincode to locate..."
                    />
                  </div>
                </div>
              </div>

              {/* Address */}
              {address && (
                <div className="mb-6 rounded-lg border border-gray-100 bg-gray-50 p-4 dark:border-navy-700 dark:bg-navy-900">
                  <div className="flex items-start gap-3">
                    <div className="rounded-full bg-brand-50 p-2 dark:bg-brand-900/20">
                      <MdLocationOn className="text-brand-500" size={20} />
                    </div>
                    <div>
                      <h4 className="text-sm font-medium text-navy-700 dark:text-white">
                        Selected Location
                      </h4>
                      <p className="mt-1 text-sm text-gray-600 dark:text-gray-400">
                        {(address as Address)?.address}
                      </p>
                    </div>
                  </div>
                </div>
              )}

              {/* Map */}
              {isMapVisible && location && (
                <div className="space-y-4">
                  <div className="flex items-center justify-center gap-2 text-sm text-gray-600 dark:text-gray-400">
                    <MdLocationOn className="text-brand-500" size={18} />
                    <span>
                      Drag the marker or click on the map to set exact location
                    </span>
                  </div>
                  <div className="overflow-hidden rounded-lg border border-gray-200 dark:border-navy-600">
                    <LoadScript googleMapsApiKey={envConfig.MapsAPIKey}>
                      <GoogleMap
                        mapContainerStyle={mapContainerStyle}
                        center={{
                          lat: location.coordinates[1],
                          lng: location.coordinates[0],
                        }}
                        zoom={14}
                        onClick={handleMapClick}
                        options={{
                          streetViewControl: true,
                          mapTypeControl: true,
                          fullscreenControl: true,
                          zoomControl: true,
                          minZoom: 3,
                          maxZoom: 20,
                          mapTypeId: "roadmap",
                          gestureHandling: "greedy",
                          styles: [
                            {
                              featureType: "poi",
                              elementType: "labels",
                              stylers: [{ visibility: "on" }],
                            },
                            {
                              featureType: "road",
                              elementType: "labels",
                              stylers: [{ visibility: "on" }],
                            },
                            {
                              featureType: "road",
                              elementType: "geometry",
                              stylers: [{ visibility: "on" }],
                            },
                            {
                              featureType: "transit",
                              elementType: "labels",
                              stylers: [{ visibility: "on" }],
                            },
                            {
                              featureType: "administrative",
                              elementType: "labels",
                              stylers: [{ visibility: "on" }],
                            },
                          ],
                        }}
                      >
                        <Marker
                          position={{
                            lat: location.coordinates[1],
                            lng: location.coordinates[0],
                          }}
                          draggable={true}
                          onDragEnd={(e) => {
                            if (e.latLng) {
                              handleMapClick({
                                latLng: e.latLng,
                              } as google.maps.MapMouseEvent);
                            }
                          }}
                        />
                      </GoogleMap>
                    </LoadScript>
                  </div>
                </div>
              )}
            </FormSection>

            {/* Media Upload */}
            <FormSection title="Restaurant Media">
              <div className="grid gap-6 md:grid-cols-2">
                {/* Logo Upload */}
                <div className="relative">
                  <label className="mb-2 block text-sm font-medium text-navy-700 dark:text-white">
                    Restaurant Logo
                  </label>
                  <Card className="grid h-[250px] w-full grid-cols-1 gap-3 rounded-[20px] bg-white bg-clip-border p-3 font-dm shadow-3xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none">
                    <div className="flex h-full w-full flex-col items-center justify-center">
                      {brandImage && !isUploading ? (
                        <div className="relative flex h-full w-full flex-col items-center justify-center rounded-xl border-[2px] border-dashed border-gray-200 py-3 dark:!border-navy-700">
                          <img
                            src={
                              brandImage && brandImage?.url
                                ? brandImage.url
                                : brandImage
                            }
                            alt="Restaurant logo"
                            className="h-[160px] w-[160px] object-contain"
                          />
                          <button
                            type="button"
                            className="absolute right-2 top-2 rounded-full bg-red-50 p-1.5 text-red-600 transition-colors hover:bg-red-100 dark:bg-red-900/30 dark:text-red-400"
                            onClick={handleDeleteLogoFile}
                          >
                            <MdClose className="h-5 w-5" />
                          </button>
                        </div>
                      ) : (
                        <button
                          onClick={() => logoInputRef.current?.click()}
                          type="button"
                          className="flex h-full w-full flex-col items-center justify-center rounded-xl border-2 border-dashed border-gray-200 py-6 transition-colors hover:border-brand-500 dark:border-navy-700 dark:hover:border-brand-400"
                        >
                          {isUploading ? (
                            <CircularProgress className="h-[80px] w-[80px]" />
                          ) : (
                            <>
                              <MdFileUpload className="text-[80px] text-brand-500 dark:text-white" />
                              <h4 className="text-center text-xl font-bold text-brand-500 dark:text-white">
                                Upload Logo
                              </h4>
                            </>
                          )}
                        </button>
                      )}
                    </div>

                    <IKContext
                      publicKey={publicKey}
                      urlEndpoint={urlEndpoint}
                      authenticator={authenticator}
                    >
                      {envConfig.IMAGE_UPLOAD_METHOD === "imagekit" ? (
                        <IKUpload
                          onError={onLogoError}
                          onSuccess={onLogoSuccess}
                          onUploadStart={onUploadProgress}
                          folder={"/restaurants"}
                          tags={["restaurants/logos"]}
                          ref={logoInputRef}
                          style={{ display: "none" }}
                          accept="image/*"
                        />
                      ) : (
                        <input
                          type="file"
                          ref={logoInputRef}
                          onChange={(e) => handleLogoUpload(e, "cloudflare")}
                          accept="image/*"
                          style={{ display: "none" }}
                        />
                      )}
                    </IKContext>
                  </Card>
                </div>

                {/* Banner Upload */}
                <div className="relative">
                  <label className="mb-2 block text-sm font-medium text-navy-700 dark:text-white">
                    Restaurant Banner
                  </label>
                  <Card className="grid h-[250px] w-full grid-cols-1 gap-3 rounded-[20px] bg-white bg-clip-border p-3 font-dm shadow-3xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none">
                    <div className="flex h-full w-full flex-col items-center justify-center">
                      {bannerImage && !isBannerUploading ? (
                        <div className="relative flex h-full w-full flex-col items-center justify-center rounded-xl border-[2px] border-dashed border-gray-200 py-3 dark:!border-navy-700">
                          <img
                            src={
                              bannerImage && bannerImage?.url
                                ? bannerImage?.url
                                : bannerImage
                            }
                            alt="Restaurant banner"
                            className="h-[160px] w-[160px] object-contain"
                          />
                          <button
                            type="button"
                            className="absolute right-2 top-2 rounded-full bg-red-50 p-1.5 text-red-600 transition-colors hover:bg-red-100 dark:bg-red-900/30 dark:text-red-400"
                            onClick={handleDeleteBannerFile}
                          >
                            <MdClose className="h-5 w-5" />
                          </button>
                        </div>
                      ) : (
                        <button
                          onClick={() => bannerInputRef.current?.click()}
                          type="button"
                          className="flex h-full w-full flex-col items-center justify-center rounded-xl border-2 border-dashed border-gray-200 py-6 transition-colors hover:border-brand-500 dark:border-navy-700 dark:hover:border-brand-400"
                        >
                          {isBannerUploading ? (
                            <CircularProgress className="h-[80px] w-[80px]" />
                          ) : (
                            <>
                              <MdFileUpload className="text-[80px] text-brand-500 dark:text-white" />
                              <h4 className="text-center text-xl font-bold text-brand-500 dark:text-white">
                                Upload Banner
                              </h4>
                            </>
                          )}
                        </button>
                      )}
                    </div>

                    <IKContext
                      publicKey={publicKey}
                      urlEndpoint={urlEndpoint}
                      authenticator={authenticator}
                    >
                      {envConfig.IMAGE_UPLOAD_METHOD === "imagekit" ? (
                        <IKUpload
                          onError={onBannerError}
                          onSuccess={onBannerSuccess}
                          onUploadStart={onBannerUploadProgress}
                          folder={"/restaurants"}
                          tags={["restaurants/banners"]}
                          ref={bannerInputRef}
                          style={{ display: "none" }}
                          accept="image/*"
                        />
                      ) : (
                        <input
                          type="file"
                          ref={bannerInputRef}
                          onChange={(e) => handleBannerUpload(e, "cloudflare")}
                          accept="image/*"
                          style={{ display: "none" }}
                        />
                      )}
                    </IKContext>
                  </Card>
                </div>
              </div>
            </FormSection>

            {/* Working Hours */}
            <FormSection title="Working Hours">
              <WorkingHoursSection
                daysOfWeek={daysOfWeek}
                setFieldValue={setFieldValue}
                values={values}
              />
            </FormSection>

            {/* Submit Button */}
            <div className="sticky bottom-0 mt-6 flex justify-end bg-gray-50 py-4 dark:bg-navy-900">
              <button
                type="submit"
                className="flex items-center gap-2 rounded-lg bg-brand-500 px-6 py-2.5 text-sm font-semibold text-white transition-colors hover:bg-brand-600 focus:outline-none focus:ring-2 focus:ring-brand-500 focus:ring-offset-2 dark:hover:bg-brand-400"
                disabled={loading}
              >
                {loading ? (
                  <>
                    <CircularProgress size={16} className="text-white" />
                    <span>Saving...</span>
                  </>
                ) : (
                  <>
                    <span className="material-icons-outlined text-lg">
                      Save & Continue
                    </span>
                  </>
                )}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default RestaurantForm;
