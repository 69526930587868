/* eslint-disable @typescript-eslint/no-unused-vars */
import { Routes, Route, Navigate } from "react-router-dom";
import React, { useContext } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { UserContext } from "controller/context/userContext";

import AdminLayout from "layouts/admin";
// import AuthLayout from "layouts/auth";
import { DefaultRedirect, RoleGuard } from "controller/services/roleGuard";
import SignIn from "views/auth/SignIn";
import TwoStepAuth from "../src/views/auth/TwoStepAuth";
import ResetPassword from "views/auth/ResetPassword";

const App: React.FC = () => {
  const { loading } = useContext(UserContext);
  const queryClient = new QueryClient();
  // const AuthWithOTP = lazy(() => import("../src/views/auth/TwoStepAuth"));

  if (loading) return null;

  return (
    <QueryClientProvider client={queryClient}>
      <Routes>
        <Route path="/" element={<DefaultRedirect />} />

        {/* Public auth routes */}
        <Route
          path="/branch/auth/sign-in/*"
          element={
            <RoleGuard>
              <SignIn />
            </RoleGuard>
          }
        />
        <Route
          path="/admin/auth/sign-in/*"
          element={
            <RoleGuard>
              <SignIn />
            </RoleGuard>
          }
        />

        {/* Reset Password route - no RoleGuard */}
        <Route
          path="/reset-password"
          element={<ResetPassword />}
        />

        {/* Legacy reset password routes - redirect to main reset route */}
        <Route
          path="/branch/auth/reset-password"
          element={<ResetPassword />}
          // element={<Navigate to="/reset-password" replace />}
        />
        <Route
          path="/admin/auth/reset-password"
          element={<ResetPassword />}
          // element={<Navigate to="/reset-password" replace />}
        />

        {/* 2FA routes */}
        <Route
          path="/branch/auth/2fa"
          element={
            <RoleGuard>
              <TwoStepAuth />
            </RoleGuard>
          }
        />
        <Route
          path="/admin/auth/2fa"
          element={
            <RoleGuard>
              <TwoStepAuth />
            </RoleGuard>
          }
        />

        {/* Protected branch routes */}
        <Route
          path="/branch/*"
          element={
            <RoleGuard>
              <AdminLayout />
            </RoleGuard>
          }
        />

        {/* Protected admin routes */}
        <Route
          path="/admin/*"
          element={
            <RoleGuard>
              <AdminLayout />
            </RoleGuard>
          }
        />
      </Routes>
    </QueryClientProvider>
  );
};

export default App;
