import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { AlertContext } from "controller/context/alertContext";
import { Alert, Snackbar } from "@mui/material";
import { branchApp } from "controller/services/dbServices";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";

export default function ResetPassword() {
  const navigate = useNavigate();
  const location = useLocation();
  const { setAlert, active, type, message } = React.useContext(AlertContext);
  const [loading, setLoading] = React.useState(false);

  // Get the path role from the referrer or default to branch
  const getRoleFromReferrer = () => {
    const referrer = document.referrer;
    if (referrer.includes('/admin/')) return 'admin';
    return 'branch';
  };

  // Validate token parameters on component mount
  React.useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get('token');
    const tokenId = params.get('tokenId');

    console.log('Reset Password - URL:', window.location.href);
    console.log('Reset Password - Token:', token);
    console.log('Reset Password - TokenId:', tokenId);
    console.log('Reset Password - Referrer:', document.referrer);

    if (!token || !tokenId) {
      setAlert({
        active: true,
        type: "error",
        message: "Invalid or expired reset link. Please request a new password reset."
      });
      // Redirect back to sign in after showing error
      setTimeout(() => {
        const role = getRoleFromReferrer();
        navigate(`/${role}/auth/sign-in`, { replace: true });
      }, 3000);
    }
  }, [location, navigate, setAlert]);

  const initialValues = {
    password: "",
    confirmPassword: "",
  };

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password")], "Passwords must match")
      .required("Confirm Password is required"),
  });

  const handlePasswordReset = async (values: any) => {
    setLoading(true);
    try {
      const params = new URLSearchParams(location.search);
      const token = params.get('token');
      const tokenId = params.get('tokenId');

      console.log('Attempting password reset with:', {
        token: token,
        tokenId: tokenId,
        newPassword: '***'
      });

      if (!token || !tokenId) {
        throw new Error("Invalid or expired reset link. Please request a new password reset.");
      }

      await branchApp.emailPasswordAuth.resetPassword({
        password: values.password,
        token: token,
        tokenId: tokenId
      });

      console.log('Password reset successful');
      setAlert({
        active: true,
        type: "success",
        message: "Password reset successful! You can now sign in with your new password."
      });

      // Clear URL parameters and redirect to sign in
      setTimeout(() => {
        const role = getRoleFromReferrer();
        navigate(`/${role}/auth/sign-in`, { replace: true });
      }, 2000);

    } catch (error: any) {
      console.error('Password reset error:', error);
      setAlert({
        active: true,
        type: "error",
        message: error.message || "Failed to reset password. Please try again or request a new reset link."
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="relative float-right h-full min-h-screen w-full !bg-white dark:!bg-navy-900">
      <main className={`mx-auto min-h-screen`}>
        <div className="relative flex">
          <div className="mx-auto flex min-h-full w-full flex-col justify-start pt-12 md:max-w-[75%] lg:h-screen lg:max-w-[1013px] lg:px-8 lg:pt-0 xl:h-[100vh] xl:max-w-[1383px] xl:px-0 xl:pl-[70px]">
            <div className="mx-auto mb-auto flex flex-col pl-5 pr-5 md:pl-12 md:pr-0 lg:max-w-[48%] lg:pl-0 xl:max-w-full">
              <div className="mb-16 mt-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
                <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
                  <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
                    Reset Password
                  </h4>
                  <p className="mb-9 ml-1 text-base text-gray-600">
                    Please enter and confirm your new password
                  </p>
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handlePasswordReset}
                  >
                    <Form className="flex flex-col gap-4">
                      <div className="mb-3">
                        <label className="ml-3 text-sm font-bold text-navy-700 dark:text-white">
                          New Password*
                        </label>
                        <Field
                          type="password"
                          name="password"
                          className="mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none"
                          placeholder="Enter your new password"
                        />
                        <ErrorMessage
                          name="password"
                          component="div"
                          className="text-red-500 text-sm mt-1"
                        />
                      </div>

                      <div className="mb-3">
                        <label className="ml-3 text-sm font-bold text-navy-700 dark:text-white">
                          Confirm Password*
                        </label>
                        <Field
                          type="password"
                          name="confirmPassword"
                          className="mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none"
                          placeholder="Confirm your new password"
                        />
                        <ErrorMessage
                          name="confirmPassword"
                          component="div"
                          className="text-red-500 text-sm mt-1"
                        />
                      </div>

                      <button
                        type="submit"
                        disabled={loading}
                        className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 disabled:opacity-50"
                      >
                        {loading ? "Resetting Password..." : "Reset Password"}
                      </button>

                      <div className="mt-4 text-center">
                        <span className="text-sm font-medium text-navy-700 dark:text-gray-600">
                          Remember your password?
                        </span>
                        <a
                          href={`/${getRoleFromReferrer()}/auth/sign-in`}
                          className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
                        >
                          Sign In
                        </a>
                      </div>
                    </Form>
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Snackbar
        open={active}
        autoHideDuration={6000}
        onClose={() => {}}
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
      >
        <Alert
          severity={type}
          className="mx-3 my-3"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
}
