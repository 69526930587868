import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { UserContext } from "controller/context/userContext";
import { AlertContext } from "controller/context/alertContext";
import { Alert, Snackbar } from "@mui/material";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { EmailResponse } from "./interface";
import { sendEmailAfterLogin } from "controller/services/authServices";
import { getPathRole } from "controller/services/roleGuard";
import { branchApp } from "controller/services/dbServices";

interface SignInProps {
  showResetModal?: boolean;
}

const SignIn: React.FC<SignInProps> = ({ showResetModal: initialShowResetModal = false }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { emailPasswordLogin } = React.useContext(UserContext);
  const { setAlert, active, type, message } = React.useContext(AlertContext);
  const [loading, setLoading] = useState(false);
  const [sendingResetEmail, setSendingResetEmail] = useState(false);
  const [showResetModal, setShowResetModal] = useState(initialShowResetModal);
  const [resetToken, setResetToken] = useState<string | null>(null);
  const [resetTokenId, setResetTokenId] = useState<string | null>(null);

  const currentPath = window.location.pathname;
  const pathRole = getPathRole(currentPath) || 'branch'; // Default to branch if no role found

  React.useEffect(() => {
    // Check if URL has reset token parameters
    const params = new URLSearchParams(location.search);
    const token = params.get('token');
    const tokenId = params.get('tokenId');
    
    // Also check hash parameters (some email clients might modify the URL)
    const hashParams = new URLSearchParams(location.hash.replace('#', ''));
    const hashToken = hashParams.get('token');
    const hashTokenId = hashParams.get('tokenId');
    
    // Use either query params or hash params
    const finalToken = token || hashToken;
    const finalTokenId = tokenId || hashTokenId;
    
    console.log('Current URL:', window.location.href);
    console.log('Search params:', location.search);
    console.log('Hash params:', location.hash);
    console.log('Token:', finalToken);
    console.log('TokenId:', finalTokenId);
    console.log('PathRole:', pathRole);
    console.log('Initial show modal:', initialShowResetModal);

    if (finalToken && finalTokenId) {
      setResetToken(finalToken);
      setResetTokenId(finalTokenId);
      setShowResetModal(true);
    }
  }, [location, pathRole, initialShowResetModal]);

  const handlePasswordReset = async (values: { password: string, confirmPassword: string }) => {
    if (!resetToken || !resetTokenId) {
      setAlert({
        active: true,
        type: "error",
        message: "Invalid or expired reset link. Please request a new password reset."
      });
      return;
    }

    setLoading(true);
    try {
      await branchApp.emailPasswordAuth.resetPassword({
        password: values.password,
        token: resetToken,
        tokenId: resetTokenId
      });

      setAlert({
        active: true,
        type: "success",
        message: "Password reset successful! You can now sign in with your new password."
      });

      // Close modal and clear tokens
      setShowResetModal(false);
      setResetToken(null);
      setResetTokenId(null);

    } catch (error: any) {
      console.error('Password reset error:', error);
      setAlert({
        active: true,
        type: "error",
        message: error.message || "Failed to reset password. Please try again."
      });
    } finally {
      setLoading(false);
    }
  };

  const handleForgotPassword = async (email: string) => {
    if (!email) {
      setAlert({
        active: true,
        type: "error",
        message: "Please enter your email first"
      });
      return;
    }

    setSendingResetEmail(true);
    try {
      console.log('Sending reset email to:', email);
      await branchApp.emailPasswordAuth.sendResetPasswordEmail({
        email,
      });
      
      setAlert({
        active: true,
        type: "success",
        message: "Password reset instructions have been sent to your email. Please check your inbox and spam folder."
      });
      console.log('Reset email sent successfully');
    } catch (error: any) {
      console.error('Password reset error:', error);
      setAlert({
        active: true,
        type: "error",
        message: error.message || "Failed to send reset email"
      });
    } finally {
      setSendingResetEmail(false);
    }
  };

  const initialValues = {
    email: "",
    password: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Please enter a valid email")
      .required("Please enter your email"),
    password: Yup.string().required("Please enter your password"),
  });

  const onSubmit = async (values: any) => {
    localStorage.setItem("isVerified", JSON.stringify(false));
    setLoading(true);
    try {
      const { authUser, selectedApp } = await emailPasswordLogin(
        values.email,
        values.password,
        pathRole
      );

      if (authUser) {
        if (selectedApp.currentUser) {
          localStorage.setItem(
            "authUser",
            JSON.stringify(selectedApp.currentUser)
          );

          const isEmailSent = (await sendEmailAfterLogin({
            toEmail: values.email,
            currentApp: selectedApp,
          })) as EmailResponse;
          if (isEmailSent?.status === 202 || isEmailSent?.status === 200) {
            setAlert({
              active: true,
              type: "success",
              message: "Email OTP sent to verify your account.",
            });
            setTimeout(() => {
              navigate(`/${pathRole}/auth/2fa`);
            }, 1000);
          } else {
            setAlert({
              active: true,
              type: "error",
              message: "Email not sent. Please try again later.",
            });
            setLoading(false);
          }
        }
        setLoading(false);
      }
    } catch (error) {
      console.error("Login error:", error);
      setLoading(false);
      setAlert({
        active: true,
        type: "error",
        message: "Login Failed!",
      });
    }
  };

  return (
    <>
      <div>
        <div className="relative float-right h-full min-h-screen w-full !bg-white dark:!bg-navy-900">
          <main className={`mx-auto min-h-screen`}>
            <div className="relative flex">
              <div className="mx-auto flex min-h-full w-full flex-col justify-start pt-12 md:max-w-[75%] lg:h-screen lg:max-w-[1013px] lg:px-8 lg:pt-0 xl:h-[100vh] xl:max-w-[1383px] xl:px-0 xl:pl-[70px]">
                <div className="mx-auto mb-auto flex flex-col pl-5 pr-5 md:pl-12 md:pr-0 lg:max-w-[48%] lg:pl-0 xl:max-w-full">
                  <div className="mb-16 mt-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
                    <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
                      <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
                        Sign In
                      </h4>
                      <p className="mb-9 ml-1 text-base text-gray-600">
                        Enter your email and password to sign in!
                      </p>
                      <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={onSubmit}
                      >
                        {({ values, isSubmitting }) => (
                          <Form className="flex flex-col gap-4">
                            <div className="mb-3">
                              <label className="ml-3 text-sm font-bold text-navy-700 dark:text-white">
                                Email*
                              </label>
                              <Field
                                type="text"
                                name="email"
                                className="mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none"
                              />
                              <ErrorMessage
                                name="email"
                                component="div"
                                className="text-red-500"
                              />
                            </div>

                            <div className="mb-3">
                              <label className="ml-3 text-sm font-bold text-navy-700 dark:text-white">
                                Password*
                              </label>
                              <Field
                                type="password"
                                name="password"
                                className="mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none"
                              />
                              <ErrorMessage
                                name="password"
                                component="div"
                                className="text-red-500"
                              />
                            </div>
                            <div className="mb-4 flex items-center justify-between">
                              <div className="flex items-center">
                                <Field
                                  type="checkbox"
                                  name="remember"
                                  className="h-4 w-4 rounded border-gray-300 text-brand-500 focus:ring-brand-500"
                                />
                                <label
                                  htmlFor="remember"
                                  className="ml-2 block text-sm text-gray-900"
                                >
                                  Remember me
                                </label>
                              </div>
                              <div className="text-sm">
                                <button
                                  type="button"
                                  disabled={sendingResetEmail}
                                  onClick={() => handleForgotPassword(values.email)}
                                  className="font-medium text-brand-500 hover:text-brand-400 disabled:opacity-50"
                                >
                                  {sendingResetEmail ? "Sending..." : "Forgot your password?"}
                                </button>
                              </div>
                            </div>
                            <button
                              type="submit"
                              className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700"
                              disabled={loading || isSubmitting}
                            >
                              {loading ? "Loading..." : "Sign In"}
                            </button>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
          <Snackbar
            open={active}
            autoHideDuration={6000}
            onClose={() => { }}
            anchorOrigin={{ horizontal: "center", vertical: "top" }}
          >
            <Alert
              severity={type}
              className="mx-3 my-3"
              variant="filled"
              sx={{ width: "100%" }}
            >
              {message}
            </Alert>
          </Snackbar>
        </div>
      </div>

      {/* Reset Password Modal */}
      <Dialog
        open={showResetModal}
        onClose={() => setShowResetModal(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Reset Your Password</DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{
              password: "",
              confirmPassword: ""
            }}
            validationSchema={Yup.object({
              password: Yup.string()
                .min(6, "Password must be at least 6 characters")
                .required("Password is required"),
              confirmPassword: Yup.string()
                .oneOf([Yup.ref('password')], "Passwords must match")
                .required("Please confirm your password")
            })}
            onSubmit={handlePasswordReset}
          >
            {({ isSubmitting }) => (
              <Form className="mt-4 space-y-4">
                <div>
                  <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                    New Password
                  </label>
                  <Field
                    type="password"
                    name="password"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-500 focus:ring-brand-500 sm:text-sm"
                  />
                  <ErrorMessage name="password" component="div" className="mt-1 text-sm text-red-600" />
                </div>

                <div>
                  <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-700">
                    Confirm New Password
                  </label>
                  <Field
                    type="password"
                    name="confirmPassword"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-500 focus:ring-brand-500 sm:text-sm"
                  />
                  <ErrorMessage name="confirmPassword" component="div" className="mt-1 text-sm text-red-600" />
                </div>

                <div className="flex justify-end space-x-3">
                  <button
                    type="button"
                    onClick={() => setShowResetModal(false)}
                    className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    disabled={isSubmitting || loading}
                    className="px-4 py-2 text-sm font-medium text-white bg-brand-600 border border-transparent rounded-md hover:bg-brand-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500 disabled:opacity-50"
                  >
                    {loading ? "Resetting..." : "Reset Password"}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default SignIn;
