import { Formik, Form, Field, FieldArray, ErrorMessage, FormikErrors, FormikTouched } from "formik";
import React, { useState } from "react";
import { MdAdd, MdInfo, MdOutlineRestaurantMenu } from "react-icons/md";
import { AiOutlineDelete } from "react-icons/ai";
import * as Yup from "yup";
import { useModifiers } from "./useModifiers";

interface Item {
  _id?: string;
  name: string;
  price: string | number;
  description?: string;
}

interface FormValues {
  items: Item[];
}

interface AddModifierProps {
  branchId: string;
  modifiersData: any;
  handleNext?: () => void;
}

const validationSchema = Yup.object().shape({
  items: Yup.array().of(
    Yup.object().shape({
      name: Yup.string()
        .required("Name is required"),
      price: Yup.number()
        .typeError("Price must be a number")
        .required("Price is required")
        .min(0, "Price must be at least 0"),
      description: Yup.string(),
    })
  ),
});

const AddModifier: React.FC<AddModifierProps> = ({
  branchId,
  modifiersData,
  handleNext,
}) => {
  const { onSubmit, initialValues, deleteModifierFromDB } =
    useModifiers(modifiersData);
  const [showTip, setShowTip] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values: FormValues) => {
    try {
      setLoading(true);
      // Only submit if there are valid modifiers
      if (values.items.length > 0) {
        const validModifiers = values.items.filter(item => item.name && item.price);
        if (validModifiers.length > 0) {
          for (const item of validModifiers) {
            await onSubmit({ ...item, branchId });
          }
        }
      }
      // Always call handleNext to proceed
      handleNext && handleNext();
    } catch (error) {
      console.error('Error submitting modifiers:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex w-full flex-col gap-5">
      <div >
        <div className="bg-gradient-to-br from-white via-blue-50/30 to-indigo-50/30 backdrop-blur-xl  p-8 relative ">
          {/* Decorative Elements */}
          <div className="absolute top-0 right-0 w-[500px] h-[500px] bg-gradient-to-br from-blue-100/20 via-indigo-200/20 to-purple-100/20 rounded-full transform translate-x-1/2 -translate-y-1/2 blur-3xl"></div>
          <div className="absolute bottom-0 left-0 w-[400px] h-[400px] bg-gradient-to-tr from-blue-100/20 via-purple-200/20 to-indigo-100/20 rounded-full transform -translate-x-1/2 translate-y-1/2 blur-3xl"></div>

          {/* Header Section */}
          <div className="relative mb-8">
            <div className="flex items-start space-x-3 mb-5">
              <div className="p-2.5 bg-gradient-to-br from-blue-500 via-indigo-500 to-purple-500 rounded-xl shadow-lg shadow-indigo-500/30 ring-4 ring-white/50">
                <MdOutlineRestaurantMenu className="text-xl text-white" />
              </div>
              <div>
                <h2 className="text-2xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-600 via-indigo-600 to-purple-600 tracking-tight">
                  Add Modifiers
                </h2>
                <p className="text-gray-600 mt-1 text-sm">
                  Enhance your menu items with customizable options
                </p>
              </div>
            </div>
            
            <div 
              className="relative bg-gradient-to-r from-blue-50 via-indigo-50/50 to-purple-50/50 rounded-xl p-4 border border-indigo-100/50 cursor-help"
              onMouseEnter={() => setShowTip(true)}
              onMouseLeave={() => setShowTip(false)}
            >
              <div className="flex items-start space-x-3">
                <div className="p-2 bg-gradient-to-br from-blue-100/50 to-indigo-100/50 rounded-lg ring-2 ring-white/80">
                  <MdInfo className="text-base text-indigo-600" />
                </div>
                <div>
                  <p className="text-sm text-gray-700 leading-relaxed">
                    Each modifier requires a name and price. These will appear as options when customers order items from your menu.
                    <span className="block mt-1.5 text-indigo-700 font-medium text-xs">
                      Pro tip: You can skip this step if you don't need modifiers.
                    </span>
                  </p>
                </div>
              </div>
              {showTip && (
                <div className="absolute top-full left-1/2 transform -translate-x-1/2 mt-2 px-4 py-2 bg-gray-900 text-white text-xs rounded-lg z-10 w-64 text-center shadow-xl">
                  Click "Skip for now" to proceed without adding modifiers
                  <div className="absolute -top-1 left-1/2 transform -translate-x-1/2 w-2 h-2 bg-gray-900 rotate-45"></div>
                </div>
              )}
            </div>
          </div>

          <div className="relative">
            <Formik<FormValues>
              initialValues={initialValues || { items: [] }}
              validationSchema={validationSchema}
              enableReinitialize={true}
              onSubmit={handleSubmit}
            >
              {({ values, errors, touched }) => (
                <Form className="space-y-6">
                  <FieldArray name="items">
                    {({ push, remove }) => (
                      <div className="space-y-8">
                        <div className="space-y-4">
                          {values.items.map((item, index) => (
                            <div
                              key={index}
                              className="group bg-white rounded-xl p-6 shadow-sm border border-gray-100"
                            >
                              <div className="flex justify-between items-center mb-6">
                                <div className="flex items-center justify-center w-8 h-8 rounded-lg bg-gradient-to-br from-blue-500/10 via-indigo-500/10 to-purple-500/10 ring-1 ring-indigo-100">
                                  <span className="text-sm font-semibold bg-gradient-to-r from-blue-600 via-indigo-600 to-purple-600 bg-clip-text text-transparent">
                                    #{index + 1}
                                  </span>
                                </div>
                                <button
                                  type="button"
                                  onClick={async () => {
                                    const modifierId = item._id;
                                    if (modifierId) {
                                      await deleteModifierFromDB(modifierId);
                                    }
                                    remove(index);
                                  }}
                                  className="flex items-center justify-center w-8 h-8 rounded-lg text-gray-400 hover:text-red-500 hover:bg-red-50 transition-colors duration-150"
                                >
                                  <AiOutlineDelete className="text-xl" />
                                </button>
                              </div>

                              <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                                <div className="space-y-2">
                                  <label 
                                    htmlFor={`items.${index}.name`}
                                    className="block text-sm font-medium text-gray-700"
                                  >
                                    Name <span className="text-red-500">*</span>
                                  </label>
                                  <div className="relative">
                                    <Field
                                      id={`items.${index}.name`}
                                      name={`items[${index}].name`}
                                      placeholder="e.g., Extra Cheese"
                                      className={`h-11 w-full rounded-lg border bg-white px-4 text-sm outline-none transition-colors duration-150 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-100 ${
                                        (errors.items?.[index] as FormikErrors<Item>)?.name && (touched.items?.[index] as FormikTouched<Item>)?.name
                                          ? 'border-red-300 focus:border-red-500 focus:ring-red-100'
                                          : 'border-gray-200'
                                      }`}
                                    />
                                    <ErrorMessage
                                      name={`items.${index}.name`}
                                      component="div"
                                      className="mt-1.5 text-sm text-red-500 font-medium"
                                    />
                                  </div>
                                </div>

                                <div className="space-y-2">
                                  <label 
                                    htmlFor={`items.${index}.price`}
                                    className="block text-sm font-medium text-gray-700"
                                  >
                                    Price <span className="text-red-500">*</span>
                                  </label>
                                  <div className="relative">
                                    <span className="absolute left-4 top-3 text-gray-500">₹</span>
                                    <Field
                                      id={`items.${index}.price`}
                                      name={`items[${index}].price`}
                                      type="number"
                                      min="0"
                                      step="0.01"
                                      placeholder="0.00"
                                      className={`h-11 w-full rounded-lg border bg-white pl-8 pr-4 text-sm outline-none transition-colors duration-150 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-100 ${
                                        (errors.items?.[index] as FormikErrors<Item>)?.price && (touched.items?.[index] as FormikTouched<Item>)?.price
                                          ? 'border-red-300 focus:border-red-500 focus:ring-red-100'
                                          : 'border-gray-200'
                                      }`}
                                    />
                                    <ErrorMessage
                                      name={`items.${index}.price`}
                                      component="div"
                                      className="mt-1.5 text-sm text-red-500 font-medium"
                                    />
                                  </div>
                                </div>

                                <div className="space-y-2">
                                  <label 
                                    htmlFor={`items.${index}.description`}
                                    className="block text-sm font-medium text-gray-700"
                                  >
                                    Description <span className="text-gray-400 text-xs">(Optional)</span>
                                  </label>
                                  <Field
                                    id={`items.${index}.description`}
                                    name={`items[${index}].description`}
                                    placeholder="Add a description..."
                                    className="h-11 w-full rounded-lg border border-gray-200 bg-white px-4 text-sm outline-none transition-colors duration-150 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-100"
                                  />
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>

                        {/* Action Buttons */}
                        <div className="flex flex-col space-y-6">
                          {values.items.length === 0 ? (
                            <div className="text-center p-8 bg-gradient-to-r from-blue-50 via-indigo-50 to-purple-50 rounded-lg border border-dashed border-indigo-200">
                              <h3 className="text-lg font-medium text-gray-800 mb-2">Add Modifiers (Optional)</h3>
                              <p className="text-gray-600 mb-6">You can add modifiers to customize your menu items, or continue without them</p>
                              <div className="flex items-center justify-center space-x-4">
                                <button
                                  type="button"
                                  onClick={() => push({ name: "", price: "", description: "" })}
                                  className="inline-flex items-center justify-center px-5 py-2.5 bg-gradient-to-r from-blue-600 via-indigo-600 to-purple-600 text-white text-sm font-medium rounded-lg hover:from-blue-700 hover:via-indigo-700 hover:to-purple-700 transition-colors duration-150"
                                >
                                  <MdAdd className="mr-2 text-lg" />
                                  Add Modifier
                                </button>
                                <button
                                  type="button"
                                  onClick={() => handleNext && handleNext()}
                                  className="inline-flex items-center justify-center px-5 py-2.5 bg-white border border-gray-200 text-gray-700 text-sm font-medium rounded-lg hover:bg-gray-50 transition-colors duration-150"
                                >
                                  Continue Without Modifiers
                                </button>
                              </div>
                            </div>
                          ) : (
                            <>
                              <div className="flex justify-center">
                                <button
                                  type="button"
                                  onClick={() => push({ name: "", price: "", description: "" })}
                                  className="flex items-center justify-center px-6 py-3 bg-white rounded-lg border-2 border-dashed border-indigo-200 text-indigo-600 font-medium hover:border-indigo-300 hover:bg-indigo-50/50 transition-colors duration-150"
                                >
                                  <MdAdd className="mr-2 text-xl" />
                                  Add Another Modifier
                                </button>
                              </div>

                              <div className="flex items-center justify-end space-x-4">
                                <button
                                  type="button"
                                  onClick={() => handleNext && handleNext()}
                                  className="px-5 py-2.5 text-sm font-medium text-gray-600 transition-colors duration-150 hover:text-gray-800 hover:bg-gray-50 rounded-lg"
                                >
                                  Skip for now
                                </button>
                                <button
                                  type="submit"
                                  disabled={loading || (values.items.length > 0 && !values.items.some(item => item.name && item.price))}
                                  className={`rounded-lg bg-gradient-to-r from-blue-600 via-indigo-600 to-purple-600 px-5 py-2.5 text-sm font-medium text-white transition-colors duration-150 hover:from-blue-700 hover:via-indigo-700 hover:to-purple-700 ${
                                    (loading || (values.items.length > 0 && !values.items.some(item => item.name && item.price))) ? 'opacity-50 cursor-not-allowed' : ''
                                  }`}
                                >
                                  {loading ? (
                                    <span className="flex items-center">
                                      <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                      </svg>
                                      Saving...
                                    </span>
                                  ) : (
                                    'Save & Continue'
                                  )}
                                </button>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    )}
                  </FieldArray>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddModifier;
