import React from "react";
import { AlertContext } from "controller/context/alertContext";
import { UserContext } from "controller/context/userContext";
import { Alert, Snackbar } from "@mui/material";
import FixedPlugin from "components/fixedPlugin/FixedPlugin";

// Custom SVG Icons
const CheckIcon = () => (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    viewBox="0 0 24 24" 
    fill="none" 
    stroke="currentColor" 
    className="w-16 h-16"
  >
    <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M22 4L12 14.01l-3-3" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

const ErrorIcon = () => (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    viewBox="0 0 24 24" 
    fill="none" 
    stroke="currentColor" 
    className="w-16 h-16"
  >
    <circle cx="12" cy="12" r="10" strokeWidth="2"/>
    <line x1="12" y1="8" x2="12" y2="12" strokeWidth="2" strokeLinecap="round"/>
    <line x1="12" y1="16" x2="12.01" y2="16" strokeWidth="2" strokeLinecap="round"/>
  </svg>
);

const LogoutIcon = () => (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    viewBox="0 0 24 24" 
    fill="none" 
    stroke="currentColor" 
    className="w-4 h-4"
  >
    <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <polyline points="16 17 21 12 16 7" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <line x1="21" y1="12" x2="9" y2="12" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

export default function TwoStepAuth() {
  const [verificationStatus, setVerificationStatus] = React.useState<'loading' | 'success' | 'error'>('loading');
  const { setAlert, active, type, message,logOutUser } = React.useContext(AlertContext);
  const { userType } = React.useContext(UserContext);
  const [isLoggingOut, setIsLoggingOut] = React.useState(false);

  React.useEffect(() => {
    const attemptVerification = async () => {
      try {
        // Simulate verification process
        localStorage.setItem("isVerified", JSON.stringify(true));
        localStorage.setItem("userType", userType);
        
        setAlert({
          active: true,
          type: "success",
          message: "Successfully verified! Redirecting to dashboard...",
        });
        
        setVerificationStatus('success');
        
        // Redirect after success
        setTimeout(() => {
          window.location.replace(`/${userType}/default`);
        }, 1500);
      } catch (error) {
        setVerificationStatus('error');
        setAlert({
          active: true,
          type: "error",
          message: "Verification failed. Please try logging out and back in.",
        });
      }
    };

    attemptVerification();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLogout = async () => {
    setIsLoggingOut(true);
    try {
      await logOutUser();
      setAlert({
        active: true,
        type: "info",
        message: "Logging out...",
      });
      setTimeout(() => {
        window.location.replace("/login");
      }, 1000);
    } catch (error) {
      setAlert({
        active: true,
        type: "error",
        message: "Logout failed. Please try again.",
      });
      setIsLoggingOut(false);
    }
  };

  return (
    <div className="relative float-right h-full min-h-screen w-full !bg-white dark:!bg-navy-900">
      <FixedPlugin />
      {verificationStatus === 'error' && (
        <div className="absolute top-4 right-4">
          <button
            onClick={handleLogout}
            disabled={isLoggingOut}
            className="flex items-center gap-2 px-4 py-2 text-sm font-medium text-gray-700 bg-white rounded-lg border border-gray-200 hover:bg-gray-50 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-200 transition-all duration-200 dark:bg-navy-800 dark:text-gray-200 dark:border-navy-700 dark:hover:bg-navy-700"
          >
            <LogoutIcon />
            {isLoggingOut ? "Logging out..." : "Logout"}
          </button>
        </div>
      )}
      
      <main className="mx-auto min-h-screen">
        <div className="relative flex">
          <div className="bg-neutral-50 dark:bg-navy-800 mx-auto flex min-h-full w-full flex-col justify-start pt-12 md:max-w-[75%] lg:h-screen lg:max-w-[1013px] lg:px-8 lg:pt-0 xl:h-[100vh] xl:max-w-[1383px] xl:px-0 xl:pl-[70px]">
            <div className="mx-auto mb-auto flex flex-col pl-5 pr-5 md:pl-12 md:pr-0 lg:max-w-[48%] lg:pl-0 xl:max-w-full">
              <div className="mb-16 mt-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
                <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
                  {verificationStatus === 'loading' && (
                    <div className="text-center space-y-6">
                      <div className="flex justify-center">
                        <div className="animate-spin rounded-full h-16 w-16 border-b-2 border-brand-500"></div>
                      </div>
                      <p className="text-sm text-gray-600 dark:text-gray-400">
                        Verifying your session...
                      </p>
                    </div>
                  )}

                  {verificationStatus === 'success' && (
                    <div className="text-center space-y-6">
                      <div className="flex items-center justify-center mb-8 text-green-500 animate-bounce">
                        <CheckIcon />
                      </div>
                      <h4 className="text-3xl font-bold text-navy-700 dark:text-white">
                        Verification Successful!
                      </h4>
                      <p className="text-sm text-gray-600 dark:text-gray-400">
                        Redirecting you to your dashboard...
                      </p>
                      <div className="flex justify-center">
                        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-brand-500"></div>
                      </div>
                    </div>
                  )}

                  {verificationStatus === 'error' && (
                    <div className="text-center space-y-6">
                      <div className="flex items-center justify-center mb-8 text-red-500">
                        <ErrorIcon />
                      </div>
                      <h4 className="text-3xl font-bold text-navy-700 dark:text-white">
                        Verification Failed
                      </h4>
                      <p className="text-sm text-gray-600 dark:text-gray-400">
                        There was an error verifying your session. Please try logging out and signing in again.
                      </p>
                    </div>
                  )}
                </div>
                
                <Snackbar
                  open={active}
                  autoHideDuration={3000}
                  onClose={() => {}}
                  anchorOrigin={{ horizontal: "center", vertical: "top" }}
                >
                  <Alert
                    severity={type}
                    className="mx-3 my-3"
                    variant="filled"
                    sx={{ width: "100%" }}
                  >
                    {message}
                  </Alert>
                </Snackbar>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

//previous code which do actuall otp verfication

// import { ErrorMessage, Field, Form, Formik } from "formik";
// import * as Yup from "yup";
// import React, { useContext } from "react";
// import {
//   sendEmailAfterLogin,
//   verifyOTP,
// } from "controller/services/authServices";
// import { UserContext } from "controller/context/userContext";
// import { AlertContext } from "controller/context/alertContext";
// import { Alert, Snackbar } from "@mui/material";
// import { EmailResponse, OTPResponse } from "./interface";
// import FixedPlugin from "components/fixedPlugin/FixedPlugin";
// // import { useNavigate } from "react-router-dom";

// export default function TwoStepAuth() {
//   // const navigate = useNavigate();
//   const [loading, setLoading] = React.useState(false);
//   const { setAlert, active, type, message } = React.useContext(AlertContext);
//   const { user, currentApp, userType } = useContext(UserContext);
//   const [resendLoading, setResendLoading] = React.useState(false);
//   const [countdown, setCountdown] = React.useState(60);
//   const [canResend, setCanResend] = React.useState(false);

//   React.useEffect(() => {
//     // Start a countdown for the resend button cooldown
//     let timer: any = null;
//     if (!canResend) {
//       timer = setInterval(() => {
//         setCountdown((prevCountdown) => {
//           if (prevCountdown <= 1) {
//             clearInterval(timer);
//             setCanResend(true);
//             return 60; // Reset for next use
//           }
//           return prevCountdown - 1;
//         });
//       }, 1000);
//     }
//     return () => clearInterval(timer);
//   }, [canResend]);

//   const initialValues = {
//     otp: "",
//   };

//   const validationSchema = Yup.object().shape({
//     otp: Yup.string().required("Please enter your otp"),
//   });

//   const onVerifyOtp = async (data: { otp: string }) => {
//     setLoading(true);
//     try {
//       const result = (await verifyOTP({
//         email: user?.profile?.email,
//         submittedOtp: data.otp,
//         currentApp,
//       })) as OTPResponse;
//       if (result.verified === true) {
//         localStorage.setItem("isVerified", JSON.stringify(true));
//         localStorage.setItem("userType", userType);
//         setAlert({
//           active: true,
//           type: "success",
//           message: result.message,
//         });
//         setLoading(false);
//         setTimeout(() => {
//           // navigate(`/${userType}/default`);
//           window.location.replace(`/${userType}/default`);
//         }, 1000);
//         // setTimeout(() => {
//         //   window.location.reload();
//         //   // navigate('/admin');
//         // }, 1000);
//       } else {
//         setAlert({
//           active: true,
//           type: "error",
//           message: result.message,
//         });
//         setLoading(false);
//       }
//     } catch (error: any) {
//       setAlert({
//         active: true,
//         type: "error",
//         message: error.message,
//       });
//       setLoading(false);
//     }
//   };

//   const onResendOtp = async () => {
//     try {
//       if (!canResend) return;
//       setResendLoading(true);
//       const isEmailSent = (await sendEmailAfterLogin({
//         toEmail: user?.profile?.email,
//       })) as EmailResponse;
//       if (isEmailSent?.status === 202 || isEmailSent?.status === 200) {
//         setAlert({
//           active: true,
//           type: "success",
//           message: "A new OTP has been sent to your email.",
//         });
//         setResendLoading(false);
//         setCanResend(false);
//         setCountdown(60);
//       }
//     } catch (error) {
//       setResendLoading(false);
//       setCanResend(false);
//       setCountdown(60);
//       setAlert({
//         active: true,
//         type: "error",
//         message: "Failed to resend OTP.",
//       });
//     }
//   };

//   return (
//     <div className="relative float-right h-full min-h-screen w-full !bg-white dark:!bg-navy-900">
//       <FixedPlugin />
//       <main className={`mx-auto min-h-screen`}>
//         <div className="relative flex">
//           <div className="bg-neutral-50 mx-auto flex min-h-full w-full flex-col justify-start pt-12 md:max-w-[75%] lg:h-screen lg:max-w-[1013px] lg:px-8 lg:pt-0 xl:h-[100vh] xl:max-w-[1383px] xl:px-0 xl:pl-[70px]">
//             <div className="mx-auto mb-auto flex flex-col pl-5 pr-5 md:pl-12 md:pr-0 lg:max-w-[48%] lg:pl-0 xl:max-w-full">
//               <div className="mb-16 mt-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
//                 {/* Sign in section */}
//                 <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
//                   <h4 className="mb-2.5 text-3xl font-bold text-navy-700 dark:text-white">
//                     Two Factor Authentication
//                   </h4>
//                   <p className="mb-5 ml-1 text-sm text-gray-600">
//                     Check Your Email: We' sent a 6-digit verification code.
//                     Please enter it below to verify your account -
//                     dev@coderythm.com.
//                   </p>
//                   <Formik
//                     initialValues={initialValues}
//                     validationSchema={validationSchema}
//                     onSubmit={onVerifyOtp}
//                   >
//                     <Form>
//                       <div className="mb-3">
//                         <label className="ml-3 text-sm font-bold text-navy-700 dark:text-white">
//                           Enter OTP *
//                         </label>
//                         <Field
//                           type="text"
//                           name="otp"
//                           className={
//                             "mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none"
//                           }
//                         />
//                         <ErrorMessage
//                           name="otp"
//                           component="div"
//                           className="text-red-500"
//                         />
//                       </div>
//                       <button
//                         className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
//                         type="submit"
//                       >
//                         {loading ? "Loading..." : "Verify OTP"}
//                       </button>
//                       <button
//                         className="linear mt-2 w-full rounded-xl bg-gray-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-gray-700 active:bg-gray-800 dark:bg-gray-400 dark:text-white dark:hover:bg-gray-300 dark:active:bg-gray-200"
//                         type="submit"
//                         disabled={!canResend}
//                         onClick={onResendOtp}
//                       >
//                         {resendLoading
//                           ? "Loading..."
//                           : `Resend OTP ${canResend ? "" : `(${countdown})`}`}
//                       </button>
//                     </Form>
//                   </Formik>
//                 </div>
//                 <Snackbar
//                   open={active}
//                   autoHideDuration={3000}
//                   onClose={() => {}}
//                   anchorOrigin={{ horizontal: "center", vertical: "top" }}
//                 >
//                   <Alert
//                     severity={type}
//                     className="mx-3 my-3"
//                     variant="filled"
//                     sx={{ width: "100%" }}
//                   >
//                     {message}
//                   </Alert>
//                 </Snackbar>
//               </div>
//             </div>
//           </div>
//         </div>
//       </main>
//     </div>
//   );
// }
